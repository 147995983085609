<template>
  <div>
    <!-- Page Heading -->
    <v-toolbar flat>
      <pageHeading pageTitle="Zeek -- Overview" />

      <v-spacer></v-spacer>
    </v-toolbar>

    <v-divider></v-divider>

    <template>
      <v-dialog v-model="showSlotDialog" v-if="selDetail != []">
        <v-card>
          <v-card-title>
            {{ selTitle }}
          </v-card-title>
          <v-card-text>
            <v-data-table
              :headers="slotHeaders"
              :items="selDetail"
              :items-per-page="100"
              :footer-props="footerProps"
              class="elevation-1"
              :sort-by="['start_time']"
              dense
            >
              <template v-slot:[`item.plan`]="{ item }">
                <div v-if="item.starttime">
                  Start {{ moment(item.starttime).format("HH:mm") }}<br />
                  To {{ moment(item.endtime).format("HH:mm") }}<br />
                  {{ parseHour(item.schedule_hour) }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.actual`]="{ item }">
                <div v-if="item.actual_starttime">
                  Start {{ moment(item.actual_starttime).format("HH:mm")
                  }}<br />
                  To {{ moment(item.actual_endtime).format("HH:mm") }}<br />
                  {{ parseHour(item.online_hour) }}
                </div>
                <div v-else>-</div>
              </template>
              <template v-slot:[`item.last_update`]="{ item }">
                {{ moment(item.last_update).format("YYYY-MM-DD hh:mm:ss") }}
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-btn color="blue darken-1" text @click="closeSlotPreview"
              >Cancel</v-btn
            >
          </v-card-actions>
        </v-card>
      </v-dialog>
    </template>
    <div class="content-wrap">
      <v-row justify="end">
        <v-col class="d-flex pl-0" cols="2" sm="2">
          <v-autocomplete
            label="Order Store"
            v-model="options.filter.order_store_uid"
            :items="rb_store_array_filtered"
            item-text="store_alias_id"
            item-value="store_uid"
            hide-details
            prepend-inner-icon="mdi-magnify"
            class="hidden-sm-and-down"
            @change="reloadData()"
          />
        </v-col>

        <v-col cols="10" sm="8" md="6">
          <v-text-field
            v-model="search"
            append-icon="mdi-magnify"
            label="Search"
            single-line
            hide-details
          ></v-text-field>
        </v-col>
      </v-row>

      <v-row>
        <v-col cols="12" sm="12" md="12">
          <v-data-table
            :headers="headers"
            :items="rosters"
            :items-per-page="50"
            :footer-props="footerProps"
            class="elevation-1"
            :sort-by="['store_name']"
            :sort-desc="[true]"
            :search="search"
            dense
          >
            <template v-slot:item="{ item }">
              <tr>
                <td>
                  <center>
                    <b>{{ item.store.store_alias_id }}</b>
                    <br />
                    <span>{{
                      item.store.delivery_zone.replace("Type: Driver Pool", "")
                    }}</span>
                    <span v-if="item.store.phone_manager">
                      {{ " phone_manager: " + item.store.phone_manager }}
                    </span>
                    <span v-if="item.store.phone_store">
                      {{ " | phone_store: " + item.store.phone_store }}
                    </span>
                  </center>
                </td>
                <td>
                  {{ item.store.driverpool_type }}
                </td>
                <td
                  v-for="slot in headersDate"
                  :key="slot.slot_date"
                  v-bind:class="isToday(slot.slot_date)"
                >
                  <!-- <div
                    v-for="schedule in item.schedules"
                    :key="schedule.name"
                    @click="
                      showSlotPreview(
                        schedule.slots[slot.slot_date].data,
                        item.store_name + ' ' + slot.slot_date
                      )
                    "
                  > -->
                  <div
                    @click="
                      showSlotPreview(
                        item.schedules[slot.slot_date].raw_data,
                        item.store.store_uid +
                          ' (' +
                          item.store.store_alias_id +
                          ')'
                      )
                    "
                  >
                    <span class="blue--text"
                      ><b>{{
                        item.schedules[slot.slot_date]
                          ? item.schedules[slot.slot_date].plan_count || 0
                          : 0
                      }}</b></span
                    >
                    /
                    <span class="green--text"
                      ><b>{{
                        item.schedules[slot.slot_date]
                          ? item.schedules[slot.slot_date].onduty_count || 0
                          : 0
                      }}</b></span
                    >
                    /
                    <span class="red--text"
                      ><b>{{
                        item.schedules[slot.slot_date]
                          ? (item.schedules[slot.slot_date].plan_count || 0) -
                            (item.schedules[slot.slot_date].onduty_count || 0)
                          : 0
                      }}</b></span
                    >
                  </div>
                </td>
              </tr>
            </template>
          </v-data-table>
        </v-col>
      </v-row>

      <v-row> </v-row>
    </div>
  </div>
</template>
<script>
import { mapGetters, mapActions } from "vuex";
import pageHeading from "@/components/common/pageHeading.vue";

export default {
  name: "FulltimePaymentSchedule",
  data: () => ({
    selSchedule: null,
    selDetail: [],
    selTitle: null,
    showSlotDialog: false,
    rb_store_array_filtered: [],
    rosters: [],
    headersDate: [],
    search: "",
    payment_records: [],
    footerProps: { "items-per-page-options": [25, 50, 100] },
    today: null,
    headers: [],
    options: {
      page: 1,
      itemsPerPage: 25,
      sortBy: ["order_created_at", "order_no"],
      sortDesc: [true, false],
      multiSort: true,
      mustSort: true,
      filter: {
        trip_name: "",
        order_no: "",
        employee_no: "",
        ln_status: [],
        datetime_range: [],
        date_start: "",
        date_end: "",
        case: null,
        scope_store_rider: null,
        order_store_uid: [],
        employee_store_uid: null,
        scope_store_type: "Order",
      },
    },
    slotHeaders: [
      { text: "Tasker ID", value: "zeek_ref_id", width: 120 },
      { text: "Client Partner ID", value: "employee_no" },
      { text: "DM Employee Name", value: "name_th" },
      { text: "Area Code", value: "area_code", width: 120 },
      { text: "Date", value: "apply_date", width: 120 },
      { text: "Plan", value: "plan", width: 120 },
      { text: "Actual", value: "actual", width: 120 },
      { text: "Amount", value: "amount", width: 120 },
      { text: "Last Update", value: "last_update" },
    ],
    validate_rules: {
      required: (v) => !!v || "This field is required",
      filesize: (v) =>
        !v || v.size < 2048 * 1024 || "Updload size should be less than 2 MB!",
      filetype: (v) => {
        if (v) {
          var ext = v.name.split(".").pop();
          return ["xlsx"].includes(ext) || "Please upload .xlsx file only";
        } else {
          return "This field is required";
        }
      },
    },
  }),
  components: {
    pageHeading,
  },
  methods: {
    ...mapActions(["genCan", "getAllRedbookStore", "getZeekOverview"]),

    parseHour(hour) {
      hour = hour.toString();
      let hours = hour.split(".");

      return (
        hours[0] +
        " Hour " +
        (Math.round((hours[1] * 60) / 100) || 0) +
        " Minute"
      );
    },
    prepareStoreData: async function () {
      const lodash = require("lodash");
      console.log("Load redbook store");
      //   this.rb_store_array = await this.getAllRedbookStore({ load_full: true });
      this.rb_store_array_filtered = await this.getAllRedbookStore();
      //   this.rb_stores = lodash.keyBy(this.rb_store_array, "store_uid");
    },

    isToday(inp_date) {
      const moment = require("moment-timezone");

      let ret_class = [];
      if (inp_date == moment().tz("Asia/Bangkok").format("YYYY-MM-DD")) {
        ret_class.push("amber");
        ret_class.push("lighten-4");
      }
      return ret_class;
    },

    showSlotPreview(detail, title) {
      this.showSlotDialog = true;
      console.log(detail);
      this.selDetail = detail;
      this.selTitle = title;
    },

    closeSlotPreview() {
      this.showSlotDialog = false;
      this.selDetail = [];
      this.selTitle = "";
    },

    async reloadData() {
      const lodash = require("lodash");
      const moment = require("moment-timezone");

      this.today = moment().tz("Asia/Bangkok");

      this.headers = [
        { text: "Store", value: "store_name", width: 200 },
        { text: "Type", value: "schedule_name_pre", width: 150 },
      ];
      this.headersDate = [];

      let start_date = moment()
        .tz("Asia/Bangkok")
        .subtract(7, "days")
        .format("YYYY-MM-DD");
      let end_date = moment()
        .tz("Asia/Bangkok")
        .subtract(1, "days")
        .format("YYYY-MM-DD");

      for (
        var m = moment(start_date);
        m.isSameOrBefore(end_date);
        m.add(1, "days")
      ) {
        this.headers.push({
          text: m.format("D MMM"),
          value: m.format("YYYY-MM-DD"),
          groupable: false,
          width: 100,
        });
        this.headersDate.push({ slot_date: m.format("YYYY-MM-DD") });
      }

      //get data

      let op = {
        start_date: start_date,
        end_date: end_date,
      };

      if (this.options.filter.order_store_uid) {
        console.log("fillter options");
        op["store_uid"] = this.options.filter.order_store_uid;
      }

      let build_rosters = await this.getZeekOverview(op);
      this.rosters = [];
      //build data
      for (let i = 0; i < build_rosters.length; i++) {
        let is_exsit_store = this.rosters.findIndex(
          (e) => e.store && e.store.store_uid == build_rosters[i].store_uid
        );

        if (is_exsit_store > -1) {
          // console.log("exits:", is_exsit_store);
          this.rosters[is_exsit_store].schedules[
            build_rosters[i].schedule_date
          ] = {
            ...build_rosters[i],
          };
        } else {
          //add new store to array

          //find store details
          let found_store = this.rb_store_array_filtered.find(
            (e) => e.store_uid == build_rosters[i].store_uid
          );

          let mock_obj = {
            store: { ...found_store },
            schedules: {},
          };
          mock_obj.schedules[build_rosters[i].schedule_date] = {
            ...build_rosters[i],
          };

          this.rosters.push(mock_obj);
        }
      }

      console.log("table reloaded", this.rosters);
    },
  },
  async beforeMount() {
    this.genCan();
    await this.prepareStoreData();
    this.reloadData();
  },

  computed: {
    ...mapGetters(["canDo", "allCategories"]),
  },
  mounted() {},
};
</script>