var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('v-toolbar',{attrs:{"flat":""}},[_c('pageHeading',{attrs:{"pageTitle":"Zeek -- Overview"}}),_c('v-spacer')],1),_c('v-divider'),[(_vm.selDetail != [])?_c('v-dialog',{model:{value:(_vm.showSlotDialog),callback:function ($$v) {_vm.showSlotDialog=$$v},expression:"showSlotDialog"}},[_c('v-card',[_c('v-card-title',[_vm._v(" "+_vm._s(_vm.selTitle)+" ")]),_c('v-card-text',[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.slotHeaders,"items":_vm.selDetail,"items-per-page":100,"footer-props":_vm.footerProps,"sort-by":['start_time'],"dense":""},scopedSlots:_vm._u([{key:"item.plan",fn:function(ref){
var item = ref.item;
return [(item.starttime)?_c('div',[_vm._v(" Start "+_vm._s(_vm.moment(item.starttime).format("HH:mm"))),_c('br'),_vm._v(" To "+_vm._s(_vm.moment(item.endtime).format("HH:mm"))),_c('br'),_vm._v(" "+_vm._s(_vm.parseHour(item.schedule_hour))+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.actual",fn:function(ref){
var item = ref.item;
return [(item.actual_starttime)?_c('div',[_vm._v(" Start "+_vm._s(_vm.moment(item.actual_starttime).format("HH:mm"))),_c('br'),_vm._v(" To "+_vm._s(_vm.moment(item.actual_endtime).format("HH:mm"))),_c('br'),_vm._v(" "+_vm._s(_vm.parseHour(item.online_hour))+" ")]):_c('div',[_vm._v("-")])]}},{key:"item.last_update",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm.moment(item.last_update).format("YYYY-MM-DD hh:mm:ss"))+" ")]}}],null,true)})],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeSlotPreview}},[_vm._v("Cancel")])],1)],1)],1):_vm._e()],_c('div',{staticClass:"content-wrap"},[_c('v-row',{attrs:{"justify":"end"}},[_c('v-col',{staticClass:"d-flex pl-0",attrs:{"cols":"2","sm":"2"}},[_c('v-autocomplete',{staticClass:"hidden-sm-and-down",attrs:{"label":"Order Store","items":_vm.rb_store_array_filtered,"item-text":"store_alias_id","item-value":"store_uid","hide-details":"","prepend-inner-icon":"mdi-magnify"},on:{"change":function($event){return _vm.reloadData()}},model:{value:(_vm.options.filter.order_store_uid),callback:function ($$v) {_vm.$set(_vm.options.filter, "order_store_uid", $$v)},expression:"options.filter.order_store_uid"}})],1),_c('v-col',{attrs:{"cols":"10","sm":"8","md":"6"}},[_c('v-text-field',{attrs:{"append-icon":"mdi-magnify","label":"Search","single-line":"","hide-details":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12","sm":"12","md":"12"}},[_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.rosters,"items-per-page":50,"footer-props":_vm.footerProps,"sort-by":['store_name'],"sort-desc":[true],"search":_vm.search,"dense":""},scopedSlots:_vm._u([{key:"item",fn:function(ref){
var item = ref.item;
return [_c('tr',[_c('td',[_c('center',[_c('b',[_vm._v(_vm._s(item.store.store_alias_id))]),_c('br'),_c('span',[_vm._v(_vm._s(item.store.delivery_zone.replace("Type: Driver Pool", "")))]),(item.store.phone_manager)?_c('span',[_vm._v(" "+_vm._s(" phone_manager: " + item.store.phone_manager)+" ")]):_vm._e(),(item.store.phone_store)?_c('span',[_vm._v(" "+_vm._s(" | phone_store: " + item.store.phone_store)+" ")]):_vm._e()])],1),_c('td',[_vm._v(" "+_vm._s(item.store.driverpool_type)+" ")]),_vm._l((_vm.headersDate),function(slot){return _c('td',{key:slot.slot_date,class:_vm.isToday(slot.slot_date)},[_c('div',{on:{"click":function($event){_vm.showSlotPreview(
                      item.schedules[slot.slot_date].raw_data,
                      item.store.store_uid +
                        ' (' +
                        item.store.store_alias_id +
                        ')'
                    )}}},[_c('span',{staticClass:"blue--text"},[_c('b',[_vm._v(_vm._s(item.schedules[slot.slot_date] ? item.schedules[slot.slot_date].plan_count || 0 : 0))])]),_vm._v(" / "),_c('span',{staticClass:"green--text"},[_c('b',[_vm._v(_vm._s(item.schedules[slot.slot_date] ? item.schedules[slot.slot_date].onduty_count || 0 : 0))])]),_vm._v(" / "),_c('span',{staticClass:"red--text"},[_c('b',[_vm._v(_vm._s(item.schedules[slot.slot_date] ? (item.schedules[slot.slot_date].plan_count || 0) - (item.schedules[slot.slot_date].onduty_count || 0) : 0))])])])])})],2)]}}])})],1)],1),_c('v-row')],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }